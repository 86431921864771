import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const Nav =styled.nav`
  background: #172554;
  color: #fff;
  height: 60px;
  width: 100%;
  font-size: 1rem;
  position: fixed;
  top:0;
  overflow: hidden;
  z-index: 10;
  transition: 0.5s all ease-in-out;
  @media screen and (min-width: 992px){
    transition: 0.5s all ease-in-out;
  }
`

export const NavbarContainer =styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  z-index: 1;
  width: 100%;
  padding: 0 1rem;
`

export const MobileIcon =styled.div`
  display: none;
  transition: 0.5s all ease-in-out;

  @media screen and (max-width: 992px){
    display:block;
    transition: 0.5s all ease-in-out;
  }
`

export const NavMenu =styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 992px){
    display: flex;
  }
`

export const NavItem =styled.li`
  height: 60px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 992px){
    display: none;
  }
`

export const NavLinks =styled(NavLink)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  gap:8px;
  height: 100%;
  cursor: pointer;

  &.active{
    box-shadow: inset 0 -3px #fff;
  }
  &:hover{
    box-shadow: inset 0 -3px #fff;
    color:inherit !important;
  }
`

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 992px){
    display: none;
  }
`

export const NavBtnLink = styled(NavLink)`
  border-radius:50px;
  background: #01bf71;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size:16px;
  outline:none;
  border: none;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  text-decoration: none;

  &.hover{
    transition: 0.2s all ease-in-out;
    background: $fff;
    color: #010606;
  }
`