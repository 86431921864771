import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import LoadingCard from "../components/generales/loading";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Noelements from "../components/generales/noelements";
import useFetch from "../hooks/useFetch";
import {Apis} from "../util/Apis";
import Table from "react-bootstrap/Table";
import dateFormat from "dateformat";
import CountdownTimer from "../components/templates/CountdownTimer";
import {SorteoItem, ViewDesktop, ViewMobile} from '../components/generales/elements';
import CabeceraTemplate from '../components/templates/cabeceraTemplate';
import {ContenedorHeader} from '../components/generales/card';
import {FaStar} from 'react-icons/fa6';

const Participantes = () => {

  const {id} = useParams()
  const {salaId} = JSON.parse(localStorage.getItem('nombreSala'))
  const params = {salaId: salaId, couponTypeId: id}
  const {data, loading} = useFetch(Apis.PC + '/coupon/group-by-customer', [], params)
  const [participantes, setParticipantes] = useState([])


  const querystring = window.location.search
  const query = new URLSearchParams(querystring)
  let fecha = query.get('fecha')

  const countDownDate = new Date(fecha).getTime();
  const now = new Date().getTime()
  const distance = countDownDate - now;

  const hideName = (name) => {
    const visibleCharacters = 3;
    const maxAsterisks = 5;
    const visiblePart = name.substring(0, visibleCharacters);
    const hiddenPart = '*'.repeat(Math.min(Math.max(name.length - visibleCharacters, 0), maxAsterisks));
    return visiblePart + hiddenPart;
  };

  useEffect(() => {
    if (data !== undefined && data !== null) {
      setParticipantes(data)
    }
  }, [data])

  const getCurrentCustomer = () => {
    const document = localStorage.getItem('document');
    const customer = participantes.find(item => item.document === document);
    return customer ? customer : null
  };

  return (
    <>
      {loading ?
        <LoadingCard />
        :
        <Container fluid style={{marginTop: '-4rem', padding: '0 1rem'}}>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <CabeceraTemplate 
              presentacion='Actualmente estas en el:'
              custom={
                <>
                  <strong>{getCurrentCustomer()?.rank ? getCurrentCustomer().rank : '--'}° PUESTO</strong>
                  <div style={{display:'flex',flexDirection:'row',gap:'6px',alignItems:'flex-end'}}>
                    
                    <span style={{fontSize:'14px'}}>Tienes {getCurrentCustomer()?.available ? getCurrentCustomer().available : '--'} puntos</span>
                    <FaStar color='#eec900' size={24} />
                  </div>
                </>
                }
               />
              <ContenedorHeader style={{marginBottom:'1rem'}}>
                {distance > 0 ?
                  <CountdownTimer targetDate={dateFormat(fecha, "yyyy-mm-dd HH:MM:ss")} />
                  :
                  <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <strong>El torneo a finalizado</strong>
                    <span style={{fontSize:'14px'}}>El ganador se publicará en breve</span>
                  </div>
                }
              </ContenedorHeader>
              
            </Col>
          </Row>

          <Row>
            {(getCurrentCustomer() !== null) && (participantes.length > 0) ?
              <>
                <Col>
                  <ViewDesktop>
                    <Table striped hover responsive size="sm">
                      <thead>
                        <tr>
                          <th className='text-center'>Rank</th>
                          <th>Jugador</th>
                          <th className='text-center'>Puntos</th>
                        </tr>
                      </thead>
                      <tbody>
                        {participantes && participantes.map((item, i) => (
                          <tr key={i}>
                            <td className='text-center'>{item.rank}</td>
                            <td>{hideName(item.firstName)} {hideName(item.lastName1)}</td>
                            <td className='text-center'>{item.total}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </ViewDesktop>

                  <ViewMobile>
                    <SorteoItem style={{gap:'8px'}}>
                      <div style={{display: 'flex', flexDirection: 'row', gap: '4px', padding: '8px 8px 0',fontWeight:600}}>
                        <span style={{textAlign: 'center', width: '40px', fontSize: '13px'}}>Rank</span>
                        <span style={{flex: 1, fontSize: '13px'}}>Cliente</span>
                        <span style={{textAlign: 'right', width: '45px', fontSize: '13px'}}>Puntos</span>
                      </div>
                      {participantes && participantes.map((item, i) => (
                        <div style={{display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center', padding: '8px', borderBottom: '1px solid #e5e5e5'}} key={i}>
                          {item.rank === 1 ? 
                          <div style={{position:'relative',display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <FaStar color='#eec900' size={32} style={{width: '40px', position: 'absolute', top: -6, left: 0,zIndex:9 }} />
                            <strong style={{textAlign: 'center', width: '40px',zIndex:10}}>{item.rank}</strong>
                            </div>
                            :
                            <span style={{textAlign: 'center', width: '40px'}}>{item.rank}</span>
                          }
                          <span style={{flex: 1, fontSize: '13px'}}>{hideName(item.firstName)} {hideName(item.lastName1)}</span>
                          <strong style={{textAlign: 'right', width: '45px'}}>{item.total}</strong>
                        </div>
                      ))}
                    </SorteoItem>
                  </ViewMobile>

                </Col>
              </>
              :
              <Col xs={12} sm={12} md={12}>
                <Noelements
                  titulo={getCurrentCustomer() !== null ? 'No hay participantes disponibles para este torneo aún' : 'Usted no está participando en este torneo'}
                  subtitulo='Vuelva a intentarlo más tarde'
                />
              </Col>
            }
          </Row>
        </Container>
      }
    </>
  );
};

export default Participantes;
