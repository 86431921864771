import React from 'react';
import DateTimeDisplay from './DateTimeDisplay'
import styled from "styled-components"
import {useCountdown} from "./useCountdown";

const CounterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  >span{
    color: #0A0A0A;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
  }
`

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <CounterContainer>
      {days > 0 && 
        <>
          <DateTimeDisplay value={days.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})} type={'DIA'} />
          <span>:</span>
        </>
      }
      <DateTimeDisplay value={hours.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})} type={'HRS'} />
      <span>:</span>
      <DateTimeDisplay value={minutes.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})} type={'MIN'}/>
      <span>:</span>
      <DateTimeDisplay value={seconds.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})} type={'SEG'} />
    </CounterContainer>
  );
};

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  if (days + hours + minutes + seconds <= 0) {
    return <></>;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
