import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Col, Container, Form, Row} from 'react-bootstrap'
import useFetch from '../hooks/useFetch'
import {Apis} from "../util/Apis"

import {CustomButton2} from '../components/generales/custombutton'
import LoadingCard from '../components/generales/loading'
import {LuUser2} from 'react-icons/lu'
import {SorteoItem} from '../components/generales/elements'
import Header from '../components/generales/header'

const BotonContainer = styled.div`
  padding: 0 0.5rem 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #fff;
  border-radius: 0 0 10px 10px;
`


const Perfil = () => {

  const {data: meData, loading} = useFetch(Apis.CAPP + '/me')

  const [document, setDocument] = useState('')
  const [firstName, setFirstName] = useState(' -- ')
  const [lastName, setLastName] = useState(' -- ')
  const [password, setPassword] = useState('123456')

  useEffect(() => {
    if (meData !== undefined && meData !== null) {
      setFirstName(meData.firstName)
      setLastName(meData.lastName)
      setDocument(meData.document)
    }
  }, [meData])

  return (
    <>
      {loading ?
        <LoadingCard/>
        :
        <Container fluid style={{marginTop: '-4rem', padding: '0 1rem'}}>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <Header
                icon={<LuUser2 size={24} />}
                title='Perfil'
                body='información básica del cliente'
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <SorteoItem>
                <strong>Datos de usuario</strong>
                <div style={{display:'flex',flexDirection:'column',gap:'12px'}}>
                  <div>
                  <label>Nombres</label>
                  <Form.Control
                    type="text"
                    id="nombres"
                    value={firstName}
                    onChange={(e) =>
                      setFirstName(e.target.value)
                    }
                  />
                  </div>
                  <div>
                    <label>Apellidos</label>
                    <Form.Control
                      type="text"
                      id="apellidos"
                      value={lastName}
                      onChange={(e) =>
                        setLastName(e.target.value)
                      }
                    />
                  </div>
                  <div>
                  <label>Docuento</label>
                    <Form.Control 
                      disabled
                      type="text"
                      id="documento"
                      value={document}
                    />
                  </div>
                  <div>
                    <label>Contraseña</label>
                    <Form.Control 
                      disabled
                      type="password"
                      id="contraseña"
                      value={password}
                      onChange={(e) =>
                        setPassword(e.target.value)
                      }
                    />
                  </div>
                </div>
                <BotonContainer>
                  <CustomButton2 size="sm" disabled>GUARDAR CAMBIOS</CustomButton2>
                </BotonContainer>

              </SorteoItem>
            </Col>
          </Row>
        </Container>
      }
    </>
  )
}

export default Perfil
