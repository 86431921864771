import styled from 'styled-components'

export const ContenedorHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 3px 10px rgb(0 0 0 / 5%);
  background-clip: border-box;
  border-radius: 16px;
  background: #fff;
  padding: 1rem;
  margin-top: 1rem;
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 425px){
    transition: 0.5s all ease-in-out;
  }
`
