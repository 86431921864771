import React, {useState} from 'react'
import {Route, Switch} from 'react-router-dom'

import MenuMobile from '../components/menumobile'
import Navbar from '../components/navbar'
import Sidebar from '../components/sidebar'
import MainContainer from '../components/contenedor'

import Recompensas from './Recompensas'
import Cupones from "./Cupones";
import Resumen from "./Resumen";
import Puntos from "./Puntos";
import Anforas from "./Anforas";
import DepositarCupones from "./DepositarCupones";
import Sorteos from "./Sorteos";
import Promociones from "./Promociones";
import Notificaciones from "./Notificaciones";
import Contactanos from "./Contactanos";
import Perfil from "./Perfil";
import Torneos from "./Torneos";
import Participantes from "./Participantes";

const Main = () => {

  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <React.Fragment>
      <MenuMobile isOpen={isOpen} toggle={toggle}/>
      <Navbar toggle={toggle}/>
      <Sidebar/>
      <MainContainer>
        <Switch>
          <Route path='/main' exact component={Resumen}/>
          <Route path='/main/cupones' exact component={Cupones}/>
          <Route path='/main/puntos' exact component={Puntos}/>
          <Route path='/main/anforas' exact component={Anforas}/>
          <Route path='/main/depositar-cupones/:id' exact component={DepositarCupones}/>
          <Route path='/main/sorteos' exact component={Sorteos}/>
          <Route path='/main/promociones' exact component={Promociones}/>
          <Route path='/main/notificaciones' exact component={Notificaciones}/>
          <Route path='/main/contactanos' exact component={Contactanos}/>
          <Route path='/main/recompensas' exact component={Recompensas}/>
          <Route path='/main/torneos' exact component={Torneos}/>
          <Route path='/main/participantes/:id' exact component={Participantes}/>
          <Route path='/main/perfil' exact component={Perfil}/>
        </Switch>
      </MainContainer>
    </React.Fragment>
  )
}

export default Main
