import React from 'react';
import {useHistory} from "react-router-dom";
import Table from "react-bootstrap/Table";
import dateFormat from "dateformat";
import {CustomButton2} from "../generales/custombutton";
import {LuBanknote, LuGift} from 'react-icons/lu';
import {SorteoItem, SorteoItemBody, SorteoItemHeader, SorteoItemText, ViewDesktop, ViewMobile} from '../generales/elements';
import {HiOutlineTrophy} from 'react-icons/hi2';

export function serializeDownload(params) {
  const searchParams = new URLSearchParams();
  Object.keys(params).filter((k) => {
    return (params[k] !== null && params[k] !== undefined)
  }).forEach(key => searchParams.append(key, params[key]));

  return searchParams
}

const TorneoTemplate = ({data}) => {

  const history = useHistory()

  const getTorneo = (id, couponName, fecha) => {
    const params = {couponName: couponName, fecha: fecha}
    history.push(`/main/participantes/${id}?${serializeDownload(params)}`)
  }

  return (
    <>
      <ViewDesktop>
        <Table striped hover responsive size="sm">
          <thead>
            <tr>
              <th className='text-center'>Hora</th>
              <th>Nombre</th>
              <th className='text-center'>Premio</th>
              <th className='text-center'>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {
              data && data.map((item, i) => (
                <tr key={i}>
                  <td className='text-center'>{dateFormat(item.fechaHora, "yyyy-mm-dd hh:MM TT")}</td>
                  <td>{item.couponTypeName}</td>
                  <td className='text-center'>{item.amount}</td>
                  <td className='text-center'>
                    <div style={{display:'flex',justifyContent:'center'}}>
                    <CustomButton2
                      onClick={() => getTorneo(item.couponTypeId, item.couponTypeName, item.fechaHora)}
                    >
                      Participantes
                    </CustomButton2>
                    </div>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </ViewDesktop>

      <ViewMobile>
        {
          data && data.map((item, i) => (
            <SorteoItem key={i}>
              <SorteoItemHeader>
                <HiOutlineTrophy size={24} />
                <div style={{display:'flex',flexDirection:'column'}}>
                <span style={{fontWeight:700,textTransform:'uppercase'}}>
                  {item.couponTypeName}
                </span>
                <span style={{fontSize:'14px',fontWeight:300}}>Torneo con puntos</span>
                </div>
              </SorteoItemHeader>
              <SorteoItemBody>
                <SorteoItemText>
                  <span>Premio:</span>
                  <strong>{item.amount + ' ' + item.premioTipo}</strong>
                  {item.premioTipo === 'soles' ?
                    <LuBanknote size={20} />
                    :
                    <LuGift size={20} />
                  }
                </SorteoItemText>
                <SorteoItemText>
                  <span>Hora:</span>
                  <strong>{dateFormat(item.fechaHora, "yyyy-mm-dd hh:MM TT")}</strong>
                </SorteoItemText>
                <CustomButton2
                  onClick={() => getTorneo(item.couponTypeId, item.couponTypeName, item.fechaHora)}
                  >
                  Participantes
                </CustomButton2>
              </SorteoItemBody>
            </SorteoItem>

          ))
        }
      </ViewMobile>


    </>
  );
};

export default TorneoTemplate;
