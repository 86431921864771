import styled from 'styled-components'

export const ViewDesktop = styled.div`
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
  background:#fff;
  border-radius: 16px;
  margin-bottom:1rem;
  transition: 0.5s all ease-in-out;
  table{
    color: #0A0A0A !important;
  }

  @media screen and (max-width: 768px){
    transition: 0.5s all ease-in-out;
    display: none;
  }
`

export const ViewMobile = styled.div`
  transition: 0.3s all ease-in-out;
  display: none;

  @media screen and (max-width: 768px){
    transition: 0.3s all ease-in-out;
    display: flex;
    flex-direction: column;
  }
`

/*Sorteo View items*/

export const SorteoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap:16px;
  filter: drop-shadow(0 0 10px rgb(0 0 0 / 5%));
  box-shadow: inset 0 -6px #172554;
  border-radius: 16px;
  margin-bottom: 1rem;
  background:#fff;
  padding:16px;
`
export const SorteoItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`
export const SorteoItemBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const SorteoItemText = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`