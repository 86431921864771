import styled from 'styled-components'

export const CustomButton1 =styled.button`
  display: flex;
  align-items:center;
  justify-content: center;
  text-align:center;
  gap:4px;
  background-color: #FFF;
  color: #0A0A0A;
  font-size:14px;
  font-weight: 500;
  padding: 0.4rem 1.5rem;
  border-radius: 16px;
  border:none;
  cursor: pointer;
  &:hover{
    background: #f5f5f5;
  }

  &:disabled{
    background-color: #fff;
    pointer-events: none;
    opacity: .65;
    color: #0a1033;
  }
`

export const CustomButton2 =styled.button`
  display: flex;
  align-items:center;
  justify-content: center;
  gap:4px;
  text-align:center;
  font-size:14px;
  font-weight: 500;
  color: #ffffff;
  background-color: #132392;
  padding: 0.4rem 1.5rem;
  border-radius: 16px;
  border:none;
  cursor: pointer;
  &:hover{
    color: #ffffff;
    background-color: #2c46f9;
    border-color: #2c46f9;
  }
  &:disabled{
    background-color: #132392;
    border-color: #132392;
    pointer-events: none;
    opacity: .65;
    color: #ffffff;
  }
`