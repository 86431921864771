import React, { useState,useEffect,useContext } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import axios from 'axios'
import {loginUser} from './actions/authentication.action'
import {Apis} from '../../util/Apis'
import AuthContext from './store/AuthContext'
import Form from 'react-bootstrap/Form'
import { CardLogin, ContenidoLogin, ContentCompany, ContentWargos, FormLabel, FormLogin, LoginContainer,NombreSala,SalaContent,SalaItem,SeleccioneSala, SelectSala, TextError } from './loginElements'

import salaList from '../data/salaList.json'
import logo from '../img/logo240.png'
import {CustomButton2} from '../generales/custombutton'
import {FaFacebook, FaInstagram, FaWhatsapp} from 'react-icons/fa6'


const Login = () => {

  const {stateUser, dispatch} = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const history = useHistory();

  const [checkPassword, setCheckPassword] = useState(false);
  const [selectCasino, setSelectCasino] = useState(false);
  const [sala, setSala] = useState({name: ' '});

  useEffect(() => {
    let checkSala = localStorage.getItem("nombreSala")
    if (checkSala !== undefined && checkSala !== null) {
      setSala(JSON.parse(checkSala))
      setSelectCasino(true)
    }
  }, [])

  const cambiarSala = () => {
    setSelectCasino(false)
    localStorage.removeItem("nombreSala")
  }

  function doLogin() {
    if (username === "" || password === "") {
      setError('Ingrese datos correctamente');
    } else {
      loginUser(username, password, sala.auth, dispatch, setError, setLoading);
    }
  }

  function verifyUserAccount() {
    setError("")
    setLoading(true)
    if (username === "") {
      setError('Ingrese datos correctamente');
      setLoading(false)
      return
    }

    let params = {document: username, salaId: sala.salaId}
    axios.get(Apis.CAPP + '/verify-account', {params: params})
      .then(function (response) {
        setLoading(false)
        if (!response.data.exists) {
          setError("Acerquese al counter para afiliarse al club de jugadores")
          return
        }

        if (response.data.account) {
          setCheckPassword(true)
        } else {
          history.push("/sign-up/" + username)
        }
      })
      .catch(function (error) {
        setLoading(false)
        setError("Hubo un error")
      })
  }

  const isLoggedIn = stateUser.isAuthenticated
  const referer = 'main'
  
  if (isLoggedIn) {
    return <Redirect to={referer}/>;
  }

  return (
    <LoginContainer>
      <CardLogin isSelect={selectCasino}>
        <ContenidoLogin>
          <img src={logo} width='120' height='120' alt='' style={{marginBottom:'0.5rem'}} />

            {selectCasino ?
              <>
                <NombreSala>Casino {sala.name} </NombreSala>
                <SelectSala onClick={cambiarSala}>cambiar sala</SelectSala>

                <FormLogin>
                  <FormLabel id="labeldocument">Documento</FormLabel>
                  <Form.Control required autoFocus autoComplete="off"
                    type="tel"
                    id="document"
                    htmlFor="labeldocument"
                    value={username}
                    onChange={(e) =>
                      setUsername(e.target.value)
                    }
                  />

                  {checkPassword ?
                    <>
                      <FormLabel id="labelcontrasena">Contraseña</FormLabel>
                      <Form.Control required
                        type="password"
                        id="contrasena"
                        htmlFor="labelcontrasena"
                        value={password}
                        onChange={(e) =>
                          setPassword(e.target.value)
                        }
                      />

                      <CustomButton2
                        style={{marginTop: "1rem"}}
                        type="submit"
                        onClick={doLogin}
                        disabled={loading}
                      >
                        {loading ? 'CARGANDO' : 'INGRESAR'}
                      </CustomButton2>
                    </>
                    :
                    <>
                      <CustomButton2
                        style={{marginTop: "1rem"}}
                        type="submit"
                        onClick={verifyUserAccount}
                        disabled={loading}
                      >
                        {loading ? 'CARGANDO' : 'VALIDAR'}
                      </CustomButton2>
                    </>
                  } 
                </FormLogin>

                <TextError>{error}</TextError>

                <ContentCompany>
                  <label >Dreams</label>
                  <div>
                    <a href={sala.facebook} target="_blank" rel="noopener noreferrer">
                      <FaFacebook color='#0a0a0a' size={26}/>
                    </a>
                    {sala.instagram != null &&
                      <a href={sala.instagram} target="_blank" rel="noopener noreferrer">
                      <FaInstagram color='#0a0a0a' size={26} />
                    </a>
                    }
                    <a href={sala.whatsapp} target="_blank" rel="noopener noreferrer">
                      <FaWhatsapp color='#0a0a0a' size={26} />
                    </a>
                  </div>
                </ContentCompany>

                <ContentWargos>
                  <span>Powered by</span>
                  <span style={{ fontWeight:"900", marginLeft:"0.2rem"}}>Wargos Technologies</span>
                </ContentWargos>
              </>
            :
            <>
              <SeleccioneSala>Seleccione el casino de su preferencia:</SeleccioneSala>
              <SalaContent>
                {
                  salaList.map((data, key) => {
                    const handleLogin = () => {
                      localStorage.setItem("nombreSala", JSON.stringify(data))
                      setSelectCasino(true)
                      setSala(data)
                    }
                    return (
                      <SalaItem key={key}>
                        <img src={data.path} rel="preload" alt={data.name} onClick={handleLogin} />
                      </SalaItem>
                    );
                  })
                }
              </SalaContent>
            </>
            }
          

        </ContenidoLogin>
      </CardLogin>
    </LoginContainer>
  )
}

export default Login
