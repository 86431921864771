import React, {useState, useEffect, useContext} from 'react'
import AuthContext from "../login/store/AuthContext"
import {logoutUser} from "../login/actions/authentication.action"
import useFetch from "../../hooks/useFetch"
import {Apis} from "../../util/Apis"

import {IconAnfora, IconPromociones, MenumobileContainer, MenumobileLink, MenumobileWrapper, PerfilContainer} from './menumobileElements'
import {CustomButton2} from '../generales/custombutton'
import {FaTimes} from 'react-icons/fa'
import {LuCalendarClock, LuLayoutDashboard, LuUser2} from 'react-icons/lu'
import {MdOutlineLocalActivity} from 'react-icons/md'
import {FaRankingStar} from 'react-icons/fa6'

const MenuMobile = ({isOpen, toggle}) => {

  const {dispatch} = useContext(AuthContext)

  const {data: meData} = useFetch(Apis.CAPP + '/me')
  const [firstName, setFirstName] = useState(' -- ')

  useEffect(() => {
    if (meData !== undefined && meData !== null) {
      setFirstName(meData.firstName)
    }
  }, [meData])

  return (
    <MenumobileContainer isOpen={isOpen} >

      <PerfilContainer>
        <span style={{fontWeight: 'bold', textTransform: 'capitalize', flex: 1}} >Bienvenido, {firstName}</span>
        <FaTimes onClick={toggle} size={24} style={{cursor: 'pointer'}} />
      </PerfilContainer>

      <MenumobileWrapper>
        <MenumobileLink to='/main' onClick={toggle} exact={true}>
          <LuLayoutDashboard size={26} />
          <span>Resumen</span>
        </MenumobileLink>
        <MenumobileLink to='/main/cupones' onClick={toggle}>
          <MdOutlineLocalActivity size={26} />
          <span>Cupones</span>
        </MenumobileLink>
        {/* <MenumobileLink to='/main/puntos' onClick={toggle}>
            
            <span>Puntos</span>
          </MenumobileLink> */}
        <MenumobileLink to='/main/anforas' onClick={toggle}>
          <IconAnfora />
          <span>Ánforas</span>
        </MenumobileLink>
        <MenumobileLink to='/main/sorteos' onClick={toggle}>
          <LuCalendarClock size={26} />
          <span>Sorteos</span>
        </MenumobileLink>
        {/* <MenumobileLink to='/main/recompensas' onClick={toggle}>
            <span>Recompensas</span>
          </MenumobileLink>*/}
        <MenumobileLink to='/main/torneos' onClick={toggle}>
          <FaRankingStar size={26} />
          <span>Torneos</span>
        </MenumobileLink>
        <MenumobileLink to='/main/promociones' onClick={toggle}>
          <IconPromociones />
          <span>Promociones</span>
        </MenumobileLink>
        {/* <MenumobileLink to='/main/notificaciones' onClick={toggle}>
          <img src={notificaciones} alt='notificaciones' width='30px'/>
            <span>Notificaciones</span>
          </MenumobileLink>
          <MenumobileLink to='/main/contactanos' onClick={toggle}>
          <img src={contactanos} alt='contactanos' width='30px'/>
            <span>Contactanos</span>
          </MenumobileLink> */}
        <MenumobileLink to='/main/perfil' onClick={toggle}>
          <LuUser2 size={26} />
          <span>Perfil</span>
        </MenumobileLink>

        <CustomButton2 onClick={() => logoutUser(dispatch)}>Cerrar sesión</CustomButton2>
      </MenumobileWrapper>
    </MenumobileContainer>
  )
}

export default MenuMobile
