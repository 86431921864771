import React, {useState, useEffect} from 'react'
import {
  IconAnfora,
  IconPromociones,
  LogoLink,
  SidebarLink,
  SidebarLogo,
  SidebarNav,
  SidebarWrap
} from './sidebarElements'
import {LuCalendarClock, LuLayoutDashboard} from "react-icons/lu";
import {MdOutlineLocalActivity} from "react-icons/md";
import {FaRankingStar} from "react-icons/fa6";

const Sidebar = () => {

  const [sala, setSala] = useState([]);

  useEffect(() => {
    let checkSala = localStorage.getItem("nombreSala")
    if (checkSala !== undefined && checkSala !== null) {
      setSala(JSON.parse(checkSala))
    }
  }, [])

  return (
    <>
      <SidebarNav>
        <SidebarLogo>
          <LogoLink to='/main'>
            <img src={sala.path} alt='Casino' height='50px' />
          </LogoLink>
        </SidebarLogo>
        <SidebarWrap>
          <SidebarLink to='/main' exact={true}>
            <LuLayoutDashboard size={26} />
            <span>Resumen</span>
          </SidebarLink>
          <SidebarLink to='/main/cupones'>
            <MdOutlineLocalActivity size={26} />
            <span>Cupones</span>
          </SidebarLink>
          {/* <SidebarLink to='/main/puntos'>
            
            <span>Puntos</span>
          </SidebarLink> */}
          <SidebarLink to='/main/anforas'>
            <IconAnfora />
            <span>Ánforas</span>
          </SidebarLink>
          <SidebarLink to='/main/sorteos'>
            <LuCalendarClock size={26} />
            <span>Sorteos</span>
          </SidebarLink>
          {/*<SidebarLink to='/main/recompensas'>
            <HiOutlineTrophy size={26} />
            <span>Recompensas</span>
          </SidebarLink>*/}
          <SidebarLink to='/main/torneos'>
            <FaRankingStar size={26} />
            <span>Torneos</span>
          </SidebarLink>
          <SidebarLink to='/main/promociones'>
            <IconPromociones />
            <span>Promociones</span>
          </SidebarLink>
          {/* <SidebarLink to='/main/notificaciones'>
            <IconNotificaciones />
            <span>Notificaciones</span>
          </SidebarLink>
          <SidebarLink to='/main/contactanos'>
            <IconContactanos />
            <span>Contactanos</span>
          </SidebarLink> */}
        </SidebarWrap>
      </SidebarNav>
    </>
  );
};

export default Sidebar
