import React from 'react'
import {useHistory} from "react-router-dom"
import Table from 'react-bootstrap/Table'
import dateFormat from 'dateformat'
import {SorteoItem, SorteoItemBody, SorteoItemHeader, SorteoItemText, ViewDesktop, ViewMobile} from '../generales/elements'

import {CustomButton2} from '../generales/custombutton'
import {LuBanknote, LuGift} from 'react-icons/lu'
import HiOutlineSlotMachine from '../icons/slot-machine'
import {MdOutlineLocalPlay} from 'react-icons/md'

const SorteoTemplate = ({datasorteo}) => {

  const salaInfo = JSON.parse(localStorage.getItem('nombreSala'))
  const history = useHistory()

  function handleClick() {
    history.push("/main/anforas");
  }

  const renderText = (item) => {
    switch (item.kind) {
      case 'HOT_SEAT':
        return 'Sorteo por máquina';
      case 'MANUAL':
        return 'Sorteo con cupones físicos';
      case 'VIRTUAL':
        return 'Sorteo con cupones virtuales';
      default:
        return 'Sorteo con cupones físicos';
    }
  };

  return (
    <>
      <ViewDesktop>
        <Table striped hover responsive size="sm">
          <thead>
            <tr>
              <th className='text-center'>Hora</th>
              <th>Promoción</th>
              <th className='text-center'>Premio</th>
              <th className='text-center'>Estado</th>
              <th className='text-center'>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {
              datasorteo.map((item, i) => (
                <tr key={i}>
                  <td className='text-center'>{dateFormat(item.fechaHora, "yyyy-mm-dd hh:MM TT")}</td>
                  <td>
                    {item.kind === 'HOT_SEAT' ?
                      <>
                        {salaInfo.salaId === 63 ? 'BIG WIN' : 'HOT SEAT'}
                      </>
                      :
                      <>
                        {item.couponTypeName}
                      </>
                    }
                  </td>
                  <td >
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '4px'}}>
                      <span>{item.amount + ' ' + item.premioTipo} </span>
                      {item.premioTipo === 'soles' ?
                        <LuBanknote />
                        :
                        <LuGift />
                      }
                    </div>
                  </td>
                  <td className='text-center'>{item.locked ? ' REALIZADO' : 'PENDIENTE'}</td>
                  <td className='text-center'>
                    <div style={{display:'flex',justifyContent:'center'}}>
                      <CustomButton2 
                        onClick={handleClick} 
                        disabled={item.locked || item.kind === 'HOT_SEAT'}
                      >
                        Participar
                      </CustomButton2>
                    </div>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </ViewDesktop>
      <ViewMobile>
        {
          datasorteo.map((item, i) => (
            <SorteoItem key={i}>
              <SorteoItemHeader>
                {item.kind === 'HOT_SEAT' ?
                  <HiOutlineSlotMachine size={24} />
                  :
                  <MdOutlineLocalPlay size={24} />
                }
                <div style={{display:'flex',flexDirection:'column'}}>
                <span style={{fontWeight:700,textTransform:'uppercase'}}>
                  {item.kind === 'HOT_SEAT' ?
                    salaInfo.salaId === 63 ? 'BIG WIN' : 'HOT SEAT'
                    :
                    item.couponTypeName
                  }
                </span>
                <span style={{fontSize:'14px',fontWeight:300}}>{renderText(item)}</span>
                </div>
              </SorteoItemHeader>
              <SorteoItemBody>
                <SorteoItemText>
                  <span>Premio:</span>
                  <strong>{item.amount + ' ' + item.premioTipo}</strong>
                  {item.premioTipo === 'soles' ?
                    <LuBanknote size={20} />
                    :
                    <LuGift size={20} />
                  }
                </SorteoItemText>
                <SorteoItemText>
                  <span>Hora:</span>
                  <strong>{dateFormat(item.fechaHora, "hh:MM TT")}</strong>
                </SorteoItemText>
                <SorteoItemText>
                  <span>Estado</span>
                  <strong>{item.locked ? ' REALIZADO' : 'PENDIENTE'}</strong>
                </SorteoItemText>
                <CustomButton2 onClick={handleClick} disabled={item.locked || item.kind === 'HOT_SEAT'}>Participar</CustomButton2>
              </SorteoItemBody>

            </SorteoItem>

          ))
        }
      </ViewMobile>
    </>
  )
}

export default SorteoTemplate
