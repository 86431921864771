import React from 'react'
import styled from 'styled-components'

const HeaderContainer = styled.div`
  display:flex;
  flex-direction: column;
  padding:16px;
  gap:8px;
  background-color: #fff;
  border-radius:16px;
  margin: 16px 0;
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
`

const Header = ({title,body,icon,subtitle}) => {
  return (
    <HeaderContainer>
      <div style={{display:'flex',flexDirection:'row',gap:'8px', alignItems:'center'}}>
        {icon}
        <span style={{fontWeight:700,textTransform:'uppercase',lineHeight:'18px'}}>{title}</span>
      </div>
      {body &&
        <div style={{fontSize:'13px',fontWeight:300}}>
          {body}
        </div>
      }
      {subtitle &&
        <div style={{display:'flex',flexDirection:'row',gap:'4px', alignItems:'center',justifyContent:'flex-end',width:'100%'}}>
          {subtitle}
        </div>
      }
    </HeaderContainer>
  )
}

export default Header