import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import anfora from '../icons/anfora.svg'
import promociones from '../icons/promociones.svg'

export const MenumobileContainer =styled.div`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s all ease-in-out;
  overflow-y: auto;
  opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`
export const PerfilContainer =styled.div`
  display: flex;
  flex-direction: row;
  background: #172554;
  color:#fff;
  padding: 0px 1rem;
  width: 100%;
  min-height: 60px;
  align-items: center;
  padding: 0 1rem;
`

export const PerfilLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size:1rem;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  text-decoration: none;
  list-style: none;
`
export const Icon =styled.div`
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
  transform: translate(-20%,0%);
`

export const MenumobileWrapper =styled.div`
  color: #0A1033;
  background: linear-gradient(180deg, #fff, transparent);
  width: 100%;
  padding: 1rem;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
`

export const MenumobileLink = styled(NavLink)`
  display: flex;
  color: #0a0a0a;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width:100%;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 1rem;
  gap:8px;

  transition: 0.3s all ease-in-out;
  &.active{
    box-shadow: inset 0 -5px #1e3a8a;
    background-color: #f5f5f5;
    color: #1e3a8a;
    cursor: pointer;
    border-radius: 10px 10px 0 0;
    transition: 0.3s all ease-in-out; 
    div{
      background-color: #1e3a8a;
    }
  }
  &:hover{
    box-shadow: inset 0 -5px #D835FA;
    background-color: #eaeaea;
    color: #0A1033;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    border-radius: 10px 10px 0 0;
  }
`
export const MenumobileLabel = styled.span`
  margin-left: 16px;
`

export const IconAnfora = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${anfora}) no-repeat center;
  mask: url(${anfora}) no-repeat center;
`

export const IconPromociones = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${promociones}) no-repeat center;
  mask: url(${promociones}) no-repeat center;
`