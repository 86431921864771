import React from 'react'
import styled from 'styled-components'

const NoElementsCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
  background-color: #fff;
  border-radius: 16px;
  background: #fff;
  padding: 1.5rem 1rem;
  transition: 0.3s all ease-in-out;
  text-align: center;
`

const Noelements = ({titulo,subtitulo}) => {
  return (
    <NoElementsCard>
        <h5>{titulo}</h5>
        <span>{subtitulo}</span>
    </NoElementsCard>
  )
}

export default Noelements
