import React, {useContext, useEffect, useState} from 'react'
import {Redirect, useHistory} from 'react-router-dom'
import {Apis} from "../../util/Apis"
import axios from 'axios'
import jwt_decode from "jwt-decode"
import AuthContext from './store/AuthContext'
import {setCurrentUser} from "./actions/authentication.action"
import {useParams} from 'react-router'

import {
  CardLogin,
  ContenidoLogin,
  CustomCheckbox,
  FormLabel,
  FormLogin,
  LoginContainer,
  TextError
} from './loginElements'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import logo from '../img/logo.png'
import {CustomButton1, CustomButton2} from '../generales/custombutton'

const Signup = () => {

  const {id} = useParams()
  const {stateUser, dispatch} = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const firstName = ''
  const lastName = ''
  const [document, setDocument] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [sala, setSala] = useState([]);

  let history = useHistory();

  useEffect(() => {
    setDocument(id)

    let salaLs = localStorage.getItem("nombreSala")
    if (salaLs !== undefined && salaLs !== null) {
      setSala(JSON.parse(salaLs))
    }
  }, [id])

  const [state, setState] = useState({checkedA: false, checkedB: false})

  const handleChange = (event) => {
    setState({...state, [event.target.name]: event.target.checked});
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const isLoggedIn = stateUser.isAuthenticated
  const referer = '/main'

  if (isLoggedIn) {
    return <Redirect to={referer}/>;
  }

  const goLogin = () => {
    history.push({
      pathname: '/',
    });
  }

  const onRegister = () => {
    setLoading(true)
    setError('')

    let data = {}
    data.tenant = 'wgtcustomer'
    data.connection = sala.auth
    //data.email = "hola@gmail.com"
    data.firstName = firstName
    data.lastName = lastName
    data.document = document
    data.username = document
    data.password = password
    data.salaId = sala.salaId

    if (password.length < 6) {
      console.log("password menor a 6")
      setError("La contraseña debe contener al menos 6 caracteres")
      setLoading(false)
      return;
    }

    if (password !== confirmPassword) {
      console.log("passwords dont match")
      setError("Las contraseñas no coinciden")
      setLoading(false)
      return
    }

    if (!state.checkedA) {
      console.log("No acepta promociones especiales")
      setError("Debes aceptar las promociones especiales")
      setLoading(false)
      return
    }

    if (!state.checkedB) {
      console.log("No acepta promociones especiales")
      setError("Debes aceptar lapolitica de privacidad de datos")
      setLoading(false)
      return
    }

    axios.post(Apis.OAUTH + '/oauth/signup', data)
      .then(function (res) {
        if (res.status === 200) {
          const token = res.data.access_token;
          localStorage.setItem("auth", token);
          const decodedToken = jwt_decode(token);
          setError("")

          axios.post(Apis.CAPP + '/register', data).then(res => {
            dispatch(setCurrentUser(decodedToken));
            setLoading(false)
          }).catch(err => {
            setLoading(false)
            setLoading(false)
            if (error.response) {
              //console.log(error.response.data); // => the response payload
              setError(error.response.data.message)
            } else {
              setError('Hubo un error desconocido')
            }
          })
        }
      })
      .catch(function (error) {
        setLoading(false)
        if (error.response) {
          //console.log(error.response.data); // => the response payload
          setError(error.response.data.message)
        } else {
          setError('Hubo un error desconocido')
        }
      })
  }


  return (
    <>
      <LoginContainer>
        <CardLogin isSelect={true}>
          <ContenidoLogin>
            <img src={logo} width='120' height='120' alt='' style={{marginBottom: '0.5rem'}}/>

            <FormLogin>
              <FormLabel>Documento</FormLabel>
              <Form.Control disabled
                            type="text"
                            id="document"
                            label="Documento"
                            variant="outlined"
                            value={document}
                            onChange={(e) =>
                              setDocument(e.target.value)
                            }
              />

              <FormLabel>Contraseña</FormLabel>
              <Form.Control required autoComplete="off"
                            type="password"
                            id="password"
                            onChange={(e) =>
                              setPassword(e.target.value)
                            }
              />

              <FormLabel>Confirmar contraseña</FormLabel>
              <Form.Control required autoComplete="off"
                            type="password"
                            id="repeatpassword"
                            onChange={(e) =>
                              setConfirmPassword(e.target.value)
                            }
              />

              <CustomCheckbox>
                <Form.Check required checked={state.checkedA} onChange={handleChange} name="checkedA"
                            style={{paddingLeft: "0", marginRight: '0.5rem'}}/>
                <label>Deseo recibir información de las <span onClick={handleShow} style={{
                  color: "#005ac1",
                  fontWeight: "bold",
                  cursor: "pointer"
                }}>Promociones especiales</span></label>
              </CustomCheckbox>

              <CustomCheckbox>
                <Form.Check required checked={state.checkedB} onChange={handleChange} name="checkedB"
                            style={{paddingLeft: "0", marginRight: '0.5rem'}}/>
                <label>Ley de Política de <a href={window.location.origin + sala.privacidad} style={{
                  color: "#005ac1",
                  fontWeight: "bold",
                  cursor: "pointer",
                  textDecoration: "none"
                }}>Privacidad de datos</a></label>
              </CustomCheckbox>

              <TextError>{error && error}</TextError>

              <div style={{
                display: "flex",
                flexDirection: 'row',
                justifyContent: "space-between",
                width: "100%",
                marginTop: '1rem'
              }}>

                <CustomButton1 onClick={goLogin} style={{width: "48%"}}>
                  REGRESAR </CustomButton1>
                <CustomButton2 size="sm" onClick={onRegister} style={{width: "48%"}} disabled={loading} type="submit">
                  {loading ? "CARGANDO" : "REGISTRARSE"}</CustomButton2>
              </div>

            </FormLogin>


          </ContenidoLogin>
        </CardLogin>
      </LoginContainer>

      <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Promociones Especiales</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Mediante su aceptación usted autoriza a <strong>{sala.company}</strong> (en adelante, “<strong>{sala.companyCorto}</strong>”), 
            a tratar sus datos personales proporcionados en el presente formulario para el envío de (i) saludos por cumpleaños y
             (ii) publicidad referente a promociones, ofertas, invitaciones a eventos y servicios de <strong>{sala.companyCorto}</strong> a través de medios 
             electrónicos y/o físicos y/o mediante llamadas telefónicas. Los datos personales obtenidos en el presente formulario 
             quedarán almacenados en el banco de datos de Clientes de <strong>{sala.companyCorto}</strong>. 
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>ACEPTAR</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Signup
