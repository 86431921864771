import styled from 'styled-components'
import {NavLink} from 'react-router-dom'

import anfora from '../icons/anfora.svg'
import promociones from '../icons/promociones.svg'

export const SidebarNav = styled.aside`
  background: #fff;
  width: 230px;
  height: 100vh;
  display: flex;
  z-index: 12;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.5s all ease-in-out;
  z-index: 10;
  box-shadow:0 0.125rem 9.375rem rgb(90 97 105 / 10%), 0 0.25rem 0.5rem rgb(90 97 105 / 12%), 0 0.9375rem 1.375rem rgb(90 97 105 / 10%), 0 0.4375rem 2.1875rem rgb(165 182 201 / 10%);

  @media screen and (max-width: 992px) {
    transition: 0.5s all ease-in-out;
    transform: translateX(-230px);
  }
`
export const SidebarLogo = styled.div`
  background-color: #fff;
  min-height: 60px;
  color: #000000b8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e1e5eb ;
`
export const LogoLink = styled(NavLink)`
  cursor:pointer;
  color: #4e4e4e;
  text-decoration: none;
  font-size: 18px;
`
export const SidebarWrap = styled.div`
  width: 100%;
  padding-top: 1rem;
  overflow-y: auto;
`

export const SidebarLink = styled(NavLink)`
  display: flex;
  color: #0a0a0a;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 1rem;
  gap:16px;

  &:hover {
    box-shadow: inset 5px 0 0 #1e3a8a;
    background-color: #f5f5f5;
    color: #1e3a8a;
    cursor: pointer;
  }
  &.active {
    box-shadow: inset 5px 0 0 #1e3a8a;
    background-color: #f5f5f5;
    color: #1e3a8a;
    div{
      background-color: #1e3a8a;
    }
  }
`
export const IconAnfora = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${anfora}) no-repeat center;
  mask: url(${anfora}) no-repeat center;
`
export const IconPromociones = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${promociones}) no-repeat center;
  mask: url(${promociones}) no-repeat center;
`