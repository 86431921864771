import React, {useState, useEffect, useContext} from 'react'
import AuthContext from "../login/store/AuthContext"
import {logoutUser} from "../login/actions/authentication.action"
import useFetch from '../../hooks/useFetch'
import {Apis} from "../../util/Apis"
import {FaBars} from 'react-icons/fa'
import {MobileIcon, Nav, NavbarContainer, NavItem, NavLinks, NavMenu} from './navbarElements'

import {CustomButton1} from '../generales/custombutton'
import {LuBellRing, LuUser2} from 'react-icons/lu'


const Navbar = ({toggle}) => {


  const {dispatch} = useContext(AuthContext)

  const {data: meData} = useFetch(Apis.CAPP + '/me')
  const [firstName, setFirstName] = useState(' -- ')

  useEffect(() => {
    if (meData !== undefined && meData !== null) {
      setFirstName(meData.firstName)
      localStorage.setItem('document', meData.document)
    }
  }, [meData])

  return (
    <>
      <Nav>
        <NavbarContainer>
          <span style={{fontWeight: 'bold', textTransform: 'capitalize'}} >Bienvenido, {firstName}</span>
          <NavMenu>
            <NavItem>
              <NavLinks to='/main/notificaciones'>
                <LuBellRing size={24} />
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='/main/perfil'>
                <LuUser2 size={24} />
                <span style={{fontWeight: 'bold'}} >Bienvenido, {firstName}</span>
              </NavLinks>
            </NavItem>
            <NavItem>
              <CustomButton1 onClick={() => logoutUser(dispatch)} >Cerrar sesión</CustomButton1>
            </NavItem>
            <MobileIcon>
              <FaBars onClick={toggle} size={24} />
            </MobileIcon>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  )
}

export default Navbar
