import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import useFetch from '../hooks/useFetch'
import {Apis} from "../util/Apis"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Cupon from '../components/templates/cuponTemplate'
import CabeceraTemplate from '../components/templates/cabeceraTemplate'
import {RiMedalLine} from "react-icons/ri"
import {MdOutlineLocalActivity} from "react-icons/md";

import LoadingCard from '../components/generales/loading'
import Noelements from '../components/generales/noelements'


export const TituloCategoria = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight:600;
  text-transform: uppercase;
  gap:4px;
`

const Resumen = () => {

  const salaInfo = JSON.parse(localStorage.getItem('nombreSala'))

  const {data, loading} = useFetch(Apis.CAPP + '/me')
  const {data: dataCoupon, loading: loadingCoupon} = useFetch(Apis.CAPP + '/point-coupons?salaId=' + salaInfo.salaId)
  const {data: dataSegment} = useFetch(Apis.PC + '/customer-segments?salaId=' + salaInfo.salaId)


  const [puntosCliente, setPuntosCliente] = useState(0)
  const [nivelCliente, setNivelCliente] = useState('--')
  const [cuponesCliente, setCuponesCliente] = useState([])
  const [nivelesCliente, setNivelesCliente] = useState([])

  let min = 0
  let minArray=[]

  useEffect(() => {
    if (dataCoupon !== undefined && dataCoupon !== null) {
      setCuponesCliente(dataCoupon.coupons)
    }
  }, [dataCoupon])

  useEffect(() => {
    if (dataSegment !== undefined && dataSegment !== null) {
      setNivelesCliente(dataSegment)
    }
  }, [dataSegment])

  useEffect(() => {
    if (data !== undefined && data !== null) {
      setPuntosCliente(data.pointsLevel)
      setNivelCliente(data.levelName)
    }
  }, [data])

  const dataFilter = nivelesCliente.filter(item => item.points > puntosCliente)
  if (dataFilter.length > 0){
    min = Math.min(...dataFilter.map(item => item.points))
    minArray = nivelesCliente.filter(item => item.points === min)[0]
  }
  
  return (
    <>
      {loadingCoupon ?
        <LoadingCard/>
        :
        <Container fluid style={{marginTop: '-4rem', padding: '0 1rem'}}>
          <Row>
            <Col xs={12} sm={12} md={12}>
              {loading ?
                <LoadingCard/>
                :
                <CabeceraTemplate 
                  presentacion='tu nivel actual es:'
                  icono={<RiMedalLine size={36}/>}
                  custom={
                  <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                    <strong>{nivelCliente != null ? nivelCliente : '---'}</strong>
                    {min - puntosCliente >= 0 &&
                      <span style={{fontSize: '14px', marginTop:' 0.5rem'}}>
                      te faltan 
                      <strong>{' '+(min - puntosCliente).toLocaleString()+' '}</strong> 
                      puntos para ser
                    <strong>{' '+minArray.name}</strong>
                    </span>
                    }
                  </div>
                  }
                />
              }
            </Col>
          </Row>

          <Row className='my-4'>
            <Col>
              <TituloCategoria>
                <MdOutlineLocalActivity size={24}/>
                <span>Cupones</span>
              </TituloCategoria>
            </Col>
          </Row>

          <Row>
            {cuponesCliente.length > 0 ?
              <>
                {cuponesCliente.map((item, i) =>
                  <Col xs={12} sm={6} md={6} key={i}>
                    <Cupon nombre={item.couponName} cantidad={item.available} porcentaje={item.percentage}
                           cuponColor={item.hexColor}/>
                  </Col>
                )}
              </>
              :
              <Col xs={12} sm={12} md={12}>
                <Noelements titulo='No hay cupones disponibles en este momento'
                            subtitulo='Vuelva a intentarlo más tarde'/>
              </Col>
            }
          </Row>

        </Container>
      }
    </>
  )
}

export default Resumen
