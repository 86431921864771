import React, {useEffect, useState} from 'react';
import LoadingCard from "../components/generales/loading";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Noelements from "../components/generales/noelements";
import useFetch from "../hooks/useFetch";
import {Apis} from "../util/Apis";
import TorneoTemplate from "../components/templates/torneoTemplate";
import {FaRankingStar} from "react-icons/fa6";
import Header from '../components/generales/header';

const Torneos = () => {

  const salaInfo = JSON.parse(localStorage.getItem('nombreSala'))
  const {data: torneosData, loading: loadingTorneos} = useFetch(Apis.SORTEOS + '/torneos/active/v2?salaId=' + salaInfo.salaId, [], {}, true);
  const [idTorneos, setIdTorneos] = useState([]);

  useEffect(() => {
    if (torneosData && torneosData.length > 0) {
      const ids = torneosData.map(torneo => torneo.couponTypeId);
      setIdTorneos(ids);
    }
  }, [torneosData]);

  return (
    <>
      {loadingTorneos ?
        <LoadingCard />
        :
        <Container fluid style={{marginTop: '-4rem', padding: '0 1rem'}}>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <Header
                icon={<FaRankingStar size={24} />}
                title='Torneos'
                body='Torneos vigentes'
                subtitle={
                  <>
                    <strong>{torneosData?.length ? torneosData.length : '0'}</strong>
                    <span> torneos</span>
                  </>
                }
              />
            </Col>
          </Row>

          <Row>
            {torneosData.length > 0 ?
              <Col>
                <TorneoTemplate data={torneosData} idTorneos={idTorneos}/>
              </Col>
              :
              <Col xs={12} sm={12} md={12}>
                <Noelements
                  titulo='No hay torneos disponibles para el día de hoy'
                  subtitulo='Vuelva a intentarlo más tarde'
                />
              </Col>
            }
          </Row>
        </Container>
      }
    </>
  );
};

export default Torneos;
