
import React from 'react'
import './App.css'
import {Switch, Route, BrowserRouter} from 'react-router-dom'
import PrivateRoute from './util/PrivateRoute'
import axios from "axios"
import Auth from './components/login/store/Auth'
import { ThemeProvider } from 'styled-components'

import Signup from './components/login/signup'
import Login from './components/login/login'
import Logout from './components/login/logout'
import Main from './pages/main'
import { dreamsColor, generalColor, GlobalStyles } from './theme'
import Bridge from "./pages/Bridge";

// Set the AUTH token for any request
axios.interceptors.request.use(function (config) {
  config.headers.site = window.location.host
  const salaId = JSON.parse(localStorage.getItem('nombreSala')).salaId;
  const token = localStorage.getItem('auth');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  if (salaId && salaId > 0) {
    config.headers.common['X-Sala-ID'] = salaId
  }
  return config;
});

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response) {
    if (401 === error.response.status) {
      window.location = '/logout';
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  } else {
    return Promise.reject(error);
  }
});

const App = () => {

  let customTheme = generalColor
  if (window.location.host === 'app.dreamsclub.pe') {
    customTheme = dreamsColor
  }
  return (
    <ThemeProvider theme={customTheme}>
      <GlobalStyles />
      <BrowserRouter>
        <Auth>
          <Switch>
            <PrivateRoute path="/main" component={Main} name="main"/>
            <Route path="/" component={Login} exact name="login"/>
            <Route path='/bridge' exact component={Bridge} />
            <Route path="/sign-up/:id" component={Signup} name="registro"/>
            <Route path="/logout" component={Logout} name="logout"/>
          </Switch>
        </Auth>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
