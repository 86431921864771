
import React from 'react'
import styled from 'styled-components'

const ContenedorPrincipal = styled.div`
  display: flex;
  width: calc(100% - 230px);
  height: calc(100vh - 60px);
  transition: 0.5s all ease-in-out;
  scroll-behavior: smooth;
  background:linear-gradient(180deg, #172554 1rem, transparent 10rem);
  margin-left: 230px;
  margin-top: 60px;

  @media screen and (max-width: 992px){
    transition: 0.5s all ease-in-out;
    margin-left: 0px;
    width: 100%;
  }
`

const ContenedorSecundario = styled.div`
  border-radius: 32px 32px 0px 0px;
  background: #f5f5f5;
  margin-top: 4rem;
  width: 100%;
  transition: 0.5s all ease-in-out;

  @media screen and (min-width: 992px){
    transition: 0.5s all ease-in-out;
    border-radius: 0px 32px 0px 0px;
  }
`

const MainContainer = (props) => {
  return (
    <ContenedorPrincipal>
      <ContenedorSecundario>
        {props.children}
      </ContenedorSecundario>
    </ContenedorPrincipal>
  )
}

export default MainContainer
