import styled from 'styled-components'

export const CustomPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 3px 10px rgb(0 0 0 / 15%);
  border-radius: 10px;
  background: linear-gradient(179deg,#D835FA 0%,#531C99 65px);
  color:#fff;
  margin:1rem 0;
  font-weight:bold;
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 425px){
    transition: 0.5s all ease-in-out;
  }
`
export const PanelHeader= styled.div`
  width:100%;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  text-align: center;
`

export const PanelBody= styled.div`
  width:100%;
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  background: #fff;
  color: #0A1033;
  font-weight: normal;
  border-radius: 10px;
  box-shadow: 0px -3px 6px rgb(0 0 0 / 40%);
`