import React, {useEffect, useState} from 'react'
import useFetch from '../hooks/useFetch'
import {Apis} from "../util/Apis"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import SorteoTemplate from '../components/templates/sorteoTemplate'
import LoadingCard from '../components/generales/loading'
import Noelements from '../components/generales/noelements'
import Header from '../components/generales/header'
import {LuGift} from 'react-icons/lu'

const Sorteos = () => {

  const salaInfo = JSON.parse(localStorage.getItem('nombreSala'))
  const {data, loading} = useFetch(Apis.SORTEOS + '/sorteos?salaId=' + salaInfo.salaId)
  const [sorteosdelDia, setSorteosdelDia] = useState([])

  useEffect(() => {
    if (data !== undefined && data !== null) {
      setSorteosdelDia(data.filter(item => item.kind !== 'TORNEO'))
    }
  }, [data])

  return (
    <>
      {loading ?
        <LoadingCard/>
        :
        <Container fluid style={{marginTop: '-4rem', padding: '0 1rem'}}>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <Header
                icon={<LuGift size={24} />}
                title='Sorteos'
                body='Sorteos programados para el dia de hoy'
                subtitle={
                  <>
                    <strong>{sorteosdelDia?.length ? data.length: '0'}</strong>
                    <span> sorteos</span>
                  </>
                }
              />
            </Col>
          </Row>

          <Row>
            {sorteosdelDia?.length > 0 ?
              <>
                <Col>
                  <SorteoTemplate datasorteo={sorteosdelDia}/>
                </Col>
              </>
              :
              <Col xs={12} sm={12} md={12}>
                <Noelements 
                  titulo='No hay sorteos disponibles para el día de hoy'
                  subtitulo='Vuelva a intentarlo más tarde'
                />
              </Col>
            }
          </Row>
        </Container>
      }
    </>
  )
}

export default Sorteos
