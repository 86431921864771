import React, {useState,useEffect} from 'react'
import styled from 'styled-components'
import { Col, Container, Form, Row } from 'react-bootstrap'
import Noelements from '../components/generales/noelements'
import { ContenedorHeader } from '../components/generales/card'
import { CustomButton2 } from '../components/generales/custombutton'
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {Autoplay, Pagination} from 'swiper'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import useFetch from "../hooks/useFetch";
import {Apis} from "../util/Apis";
import {MdOutlineWhatsapp} from 'react-icons/md'

SwiperCore.use([Pagination,Autoplay]);

const PromoImg = styled.img`
  box-shadow: 0px 0 10px rgb(0 0 0 / 50%);
  border-radius: 8px;
  margin-bottom: 1rem;
`

const PromocionesForm = styled.form`
  margin: 2rem 0px 1rem;
  width: 100%;
  text-align: center;
`

const Promociones = () => {

  const salaInfo = JSON.parse(localStorage.getItem('nombreSala'))
  const [sala, setSala] = useState([]);
  const {data} = useFetch(Apis.PC + `/ads-banners?salaId=${salaInfo.salaId}`, []);
  const [mensaje, setMensaje] = useState('Hola, quisiera saber más sobre las promociones!');
  const imgUrl = "https://wgt-tmp-uploads.s3.amazonaws.com/"

  useEffect(() => {
    let checkSala = localStorage.getItem("nombreSala")
    if (checkSala !== undefined && checkSala !== null) {
      setSala(JSON.parse(checkSala))
    }
  }, [])

  const redirectToWhatsApp = () => {
    const mensajeCodificado = encodeURIComponent(mensaje);
    const url = `https://wa.me/${sala.whatsapp}?text=${mensajeCodificado}`;
    window.open(url, '_blank');
  };

  return (
    <Container fluid style={{marginTop:'-4rem',padding:'0 1rem'}} >
      <Row>
        <Col xs={12} sm={12} md={12}>
          <ContenedorHeader style={{marginBottom:'1rem'}}>
            <img src={`/${sala.path}`} alt='Casino' height='80px' />

            <PromocionesForm>
              <span style={{fontSize:'0.9rem'}}>Consulte a nuestro asesores sobre nuestras promociones: </span>
              <Form.Control as="textarea" rows={3} placeholder="Hola!"
                style={{marginTop:'0.5rem'}}
                value={mensaje}
                onChange={(e) =>
                    setMensaje(e.target.value)
                  }
                />
            </PromocionesForm>

            <CustomButton2 onClick={redirectToWhatsApp}>
              <MdOutlineWhatsapp size={24} />
              <span>PREGUNTAR</span>
            </CustomButton2>

          </ContenedorHeader>
        </Col>

        {data?.length <= 0 ?
          <Col xs={12} sm={12} md={12}>
            <Noelements titulo="No hay promociones disponibles en este momento" subtitulo="Vuelva a consultar mas tarde"/>
          </Col>
          :
          <>
          {salaInfo.salaId === 60 ?
            <Col xs={12} sm={12} md={12}>
              <Swiper 
                loop={true}
                autoplay={{
                  "delay": 2500,
                  "disableOnInteraction": false,
                }}
                pagination
                slidesPerView={1}
                spaceBetween={8}
                initialSlide={1}
              >
                {data.map(item => {
                  return <SwiperSlide key={item.id}>
                      <PromoImg 
                        src={`${imgUrl}${item.url}`} 
                        alt="img"
                        style={{margin:'0.5rem',width:'calc(100% - 1rem)'}}
                      />
                    </SwiperSlide>
                })}
              </Swiper>
            </Col>
            :
            <>
              {data.map(item =>
                <Col xs={12} sm={12} md={6}  key={item.id}>
                  <PromoImg src={`${imgUrl}${item.url}`} width='100%' alt="promo"/>
                </Col>
              )}
            </> 
          }
          </>
        }
      </Row>
    </Container>
  )
}

export default Promociones
