import React from 'react'
import styled from 'styled-components'

const CabeceraContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:12px;
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
  border-radius: 16px;
  background: #fff;
  padding: 1rem;
  margin-top: 1rem;
  transition: 0.3s all ease-in-out;
  @media screen and (max-width: 425px){
    transition: 0.3s all ease-in-out;
  }
`
const CabeceraTitulo =styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size:0.8rem;
  justify-content: center;
  width:100%;
`
const InfoPuntosCantidad =styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap:4px;
  width:100%;
  text-transform:lowercase;
  strong{
    font-size:1.3rem;
  }
`
const CabeceraImg = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
  justify-content: center;
`

 const CabeceraTemplate = ({nombreitem, presentacion, icono, cantidad,custom}) => {
  return (
    <CabeceraContainer>
      <CabeceraTitulo>
        <span>{presentacion}</span>
      </CabeceraTitulo>
        {icono &&
          <CabeceraImg>
            {icono}
          </CabeceraImg>
        }
        {cantidad>=0 &&
          <InfoPuntosCantidad>
            <span><strong >{cantidad}</strong> {nombreitem}</span>
          </InfoPuntosCantidad>
        }
        {custom &&
          <>
            {custom}
          </>
        }

    </CabeceraContainer>
  )
}

export default CabeceraTemplate